
import Vue from 'vue'
import { cubeApi, getStreamId } from '@/api/stream'
import Button from 'primevue/button'
import EmptySearch from '@/components/common/EmptySearch.vue'
import Spinner from '@/components/common/Spinner.vue'

export default Vue.extend({
  name: 'VideoFrame',
  components: { Spinner, EmptySearch, Button },
  props: {
    cameraId: {
      type: String as () => string | null,
      default: null
    },
    playerCredentials: {
      type: Object as () => TPlayerCredentials,
      default: null
    }
  },
  data() {
    return {
      frame: null as string | null,
      status: 'init' as TFetchingStatus,
    }
  },
  mounted() {
    this.getVideoFrame()
  },
  methods: {
    async getStreamIdFromCube(cameraId: string) {
      try {
        return await getStreamId(cubeApi, cameraId)
      } catch (e) {
        console.log(e, 'GET STREAM ID ERROR')
      }
    },
    async getVideoFrame(): Promise<void> {
      this.status = 'loading'
      if (!this.cameraId) {
        throw new Error('CAMERA ID NOT FOUND')
      }
      const streamId = await this.getStreamIdFromCube(this.cameraId)
      const { login, password } = this.playerCredentials

      if (!streamId) {
        this.status = 'error'
        throw new Error('STREAM ID NOT FOUND')
      }

      const requestParams: IFrameRequest = {
        command: 'show_monitor',
        camera_list: streamId,
        auto_logon: '1',
        close_current_session: '1',
        transport: 4,
        login,
        password,
        mode: 1,
        language: 1
      }

      try {
        const { data } = await this.$api.get('webview/video', { params: requestParams })
        this.frame = data
        this.status = 'complete'
      } catch (e) {
        this.status = 'error'
        console.log(e, '[POST] - VIDEO FRAME ERROR')
      }
    },
  }
})
