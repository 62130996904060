(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("single-spa-vue"), require("vue-i18n"), require("vue"), require("@netvision/lib-api-repo"), require("primevue/button"));
	else if(typeof define === 'function' && define.amd)
		define(["single-spa-vue", "vue-i18n", "vue", "@netvision/lib-api-repo", "primevue/button"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("single-spa-vue"), require("vue-i18n"), require("vue"), require("@netvision/lib-api-repo"), require("primevue/button")) : factory(root["single-spa-vue"], root["vue-i18n"], root["vue"], root["@netvision/lib-api-repo"], root["primevue/button"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__4171__, __WEBPACK_EXTERNAL_MODULE__85b3__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE__9463__, __WEBPACK_EXTERNAL_MODULE_f095__) {
return 