import { CubeMixin, TUnionRepo, repositoryGetter } from '@netvision/lib-api-repo'
import { LibApiRepository } from '@netvision/lib-api-repo/dist/src/repositories/LibApiRepository'

const isCubeMixinsApi = (api:TUnionRepo): api is (CubeMixin & LibApiRepository) => 'cubeGetEntities' in api

export const cubeApi = repositoryGetter({ name: 'lib-api', mixins: ['cubejs'] })

export const getStreamId = async (
    api: TUnionRepo,
    cameraId: string,
): Promise<string | null> => {
    if (!isCubeMixinsApi(api)) return null

    const { results } = await api?.cubeGetEntities<{id: string, externalId: string}>({
        dimensions: ['Stream.id', 'Stream.externalId'],
        filters: [
            {
                member: 'Stream.cameraId',
                operator: 'equals',
                values: [cameraId]
            },
            {
                member: 'Stream.externalSystemName',
                operator: 'equals',
                values: ['SecureOS']
            }
        ]
    })

    const streamId = results[0]?.externalId
    return streamId || null
}
