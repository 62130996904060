
import Vue from 'vue'
import VideoFrame from '@/components/VideoFrame.vue'

export default Vue.extend({
  name: 'App',
  components: { VideoFrame },
  data() {
    return {
      onClose: () => {},
      cameraId: null as string | null,
      playerCredentials: {} as TPlayerCredentials
    }
  },
  beforeMount() {
    this?.$parent?.props?.onClose && (this.onClose = this.$parent.props.onClose)
    this.cameraId = this?.$parent?.props?.camera?.id || null
    this.playerCredentials = this.setPlayerCredentials()
  },
  methods: {
    setPlayerCredentials() {
      const { playerLogin: login, playerPassword: password } = this.$parent?.props

      if (!login || !password) {
        throw new Error('Player credentials not found')
      }

      return { login, password }
    }
  }
})
