import './set-public-path'
import api from './api/axios'
import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import VueI18n from 'vue-i18n'
import { RUSSIAN_TRANSLATIONS } from './translations/ru'

import App from './App.vue'

// register globally api - globally axios instance
Vue.prototype.$api = api

Vue.use(VueI18n)
Vue.config.productionTip = false

const locale = 'ru'
const TRANSLATIONS = {
  ru: RUSSIAN_TRANSLATIONS,
}
const i18n = new VueI18n({
  locale,
  messages: TRANSLATIONS,
})

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    i18n,
    render: (h: any) => h(App),
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = (props: any) => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement('div')
    props.domElement.appendChild(el)
    props.domElement = el
  }
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount
export const update = vueLifecycles.update
